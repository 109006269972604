import React from "react";
import ReactDOM from "react-dom";
import App from "./Components/App";
import {Router} from "@reach/router";

import DosPage      from "./Components/Dosser/DosPage";
import HomePage     from "./Components/Home/HomePage";
import Instructions from "./Components/Home/Instructions";
import SignInSide   from "./Components/User/Login/Login";
import SignUp       from "./Components/User/Registration/Registration";
import EditUser     from "./Components/User/EditUser/EditUser";
import Logger       from "./Components/Logger/Logger";
import Calc         from "./Components/Calc/Calculations";
import Settings     from "./Components/Settings/Settings";
import Telemetry    from "./Components/Telemetry/Telemetry";


import {StoreProvider} from "./api/store/Store";
import "./index.css";

ReactDOM.render(
    <StoreProvider>
        <Router>
            <App path='/'>
                <HomePage path='/'/>
                <DosPage path='/doser'/>
                <SignInSide path='/login'/>
                <SignUp path='/signup'/>
                <EditUser path='/edituser'/>
                <Calc path='/calc'/>
                <Logger path='/logger'/>
                <Settings path='/settings'/>
                <Telemetry path='/telemetry'/>
                <Instructions path='/instructions'/>
            </App>
        </Router>
    </StoreProvider>,
    document.getElementById("root")
);
