import React, {useEffect, useState} from "react";
import {Store} from "../../api/store/Store";
import {fetchPumpDataAction, fetchPumpUsedVolume} from "../../api/actions/Actions";
// import {fetchPumpDataAction} from "../../api/actions/Actions";

const Pump = React.lazy(() => import("./Pump"));

export default function HomePage() {
    const {state, dispatch} = React.useContext(Store);
    const url = state.urls;

    let [lang, setLang] = React.useState(state.lang);
    useEffect(() => {
        setLang(state.lang);
    },[state.lang]);


    useEffect(() => {
        // console.log(state);
        fetchPumpDataAction(dispatch, url.GET_SETTINGS);
        fetchPumpUsedVolume(dispatch, url.GET_SETTINGS);
    },[]);
    // data for pomps settings was received when App loaded
    let [pumpKey, pumpKey_edit] = useState([100,200,300,400]);

    // useEffect(() => {
    //     const newPumpKey = pumpKey;
    //     newPumpKey[0] = pumpKey[0]+1;
    //     // console.log("new value", newPumpKey[0]);
    //     pumpKey_edit(newPumpKey);
    // },[state.dosers[0].turnedOn]);

    return (
        <React.Fragment>
            <React.Suspense fallback={<div>Loading...</div>}>

                { state.isslogged ? <div className="card-columns mt-3 ml-3 mr-3">
                    {
                        state.pump_count.length > 0 && state.pump_count.map((pump, index) => {
                            // console.log("pump:", pump, state.dosers[index].turnedOn );
                            // console.log(pump, index);
                            return (
                                <Pump
                                    key={ index }
                                    pump={index}
                                    lang ={lang}
                                />
                            );
                        })
                    }
                </div> :
                <div><h3>Authorization is need</h3></div>
                }

            </React.Suspense>
        </React.Fragment>
    );
}
