import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

function getSteps() {
    return [
        'Регистрация на сайте',
        'Настройка бота Telegram',
        'Подключение к сети WiFi',
        'Сохранение пароля к Вашей сети WiFi, токенов для Telegram и LabAqua'
    ];
}

const styles = {
    image: {
        width: "27%",
        height: "auto"
    }
};

function getStepContent(step) {
    switch (step) {
        case 0:
            return <p>Вам необходимо зарегестрироваться на сайте <a
                href="https://labaqua.net/login"> labaqua.net</a>{" "}
                для получения уникального кода (токен) с помощью которого Ваше устройство будет идентифицировано в нашей
                информационной системе.
                После регистрации Вы получите письмо: <p>Your Customer id is <span
                    style={{color: "green"}}>a&?#-1bLKY4&#S4hN5e4591cd9b3f12.12345678</span> - you need to store this id
                </p>
                Не предоставляйте Ваш токен постороним людям.</p>;
        case 1:
            return <div>
                <p>Для получения информационных сообщений Вам необходимо создать личного Telegram бота и получить его
                    уникальный id,
                    являющийся одновременно и токеном. Для этого в Telegram существует специальный бот — @BotFather</p>
                <p>
                    1) в поле поиска Telegram введите <i>botfather</i> и кликните на кортинку контакта:
                    <div style={{textAlign: "center"}}>
                        <img style={styles.image} src="https://labaqua.net/img/img_site/1.jpg" alt=""/>
                    </div>
                </p>
                <p>
                    2) отправте сообщение <i>start</i>, кликните по <i>/newbot</i> и следуйте инструкциям:
                    <div style={{textAlign: "center"}}>
                        <img style={styles.image} src="https://labaqua.net/img/img_site/2.jpg" alt=""/>
                    </div>
                </p>
                <p>
                    3) придумайте имя бота и отправьте его в сообщении
                    <div style={{textAlign: "center"}}>
                        <img style={styles.image} src="https://labaqua.net/img/img_site/3.jpg" alt=""/>
                    </div>
                </p>
                <p>
                    4) скопируйте имя бота, добавтье в конце "_bot" отправьте сообщение. Если все сделано правильно Вы
                    получите сообщение со ссылкой
                    на Ваш Telegram бот и токен
                    <div style={{textAlign: "center"}}>
                        <img style={styles.image} src="https://labaqua.net/img/img_site/4.jpg" alt=""/>
                    </div>
                </p>
                <p>
                    5) кликнув по ссылке - откроется чат с Вашим ботом:
                    <div style={{textAlign: "center"}}>
                        <img style={styles.image} src="https://labaqua.net/img/img_site/5.jpg" alt=""/>
                    </div>
                </p>

            </div>;
        case 2:
            return `Подключите устройство к сети. На ноутбуке или мобилке откройте сканер сетей WiFi.
            Должно появиться устройство с названием "LabAqua_xxxx". подключитесь к устройству используя пароль указанный на коробке`;
        case 3:
            return `Перейдите в браузере по адресу 192.168.1.1, выберите сеть WiFi, введите пароль, скопируйте в поля токены и 
            нажмите "Сохранить".`;
        default:
            return 'Unknown step';
    }
}

export default function Instructions() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className={"container"}>

            <h2><i style={{color: "#ff3e55"}}>концепт инструкций, текст не читать, это набросок))</i></h2>
            <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} className={classes.button}>
                            Reset
                        </Button>
                    </Paper>
                )}
            </div>
        </div>
    );
}