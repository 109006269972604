import React, {useContext, useEffect} from "react";
import {navigate} from "@reach/router";
import {Store} from "../api/store/Store";
import PropTypes from "prop-types";

import Navbar from '../Components/Navbar/Navbar';
import {getCookie, post_return} from "../api/functions/post";


import CookieConsent from "react-cookie-consent";
import {checkIsLogged, setAvatar} from "../api/actions/Actions";

import {SnackbarProvider, useSnackbar} from 'notistack';

function App(props) {
    //
    // const {enqueueSnackbar} = useSnackbar();

    const {state, dispatch} = useContext(Store);
    const url = state.urls; // console.log(url);

    useEffect(() => {
        if (getCookie("jwt").length > 30) {
            (async function () {
                try {
                    const response = await post_return(url.VALIDATE_TOKEN, {});
                    if (response.status === 200) {
                        // console.log(response.body);
                        checkIsLogged(true, dispatch);
                        // handleClickVariant("you are logged in", "success");
                        //navigate("/")
                    } else {
                        console.log("response.body", response.body);
                        alert(response.body);
                        // handleClickVariant("you are not logged in", "error");
                        navigate("/login");
                    }
                } catch (e) {
                    console.error('Error ', e);
                }
            })();
        }
    }, []);


    useEffect(() => {
        if (getCookie("jwt").length > 30) {
            (async function () {
                try {
                    const response = await post_return(url.GET_PHOTOS, {mode: 101});
                    if (response.status === 200) {
                        // console.log(response);
                        if (response.body.row.path_url !== undefined) {
                            setAvatar("https://labaqua.net/img/" + response.body.row.path_url, dispatch);
                        }
                    } else {
                        alert(response.body);
                    }
                } catch (e) {
                    console.error('Error ', e);
                }
            })();
        }
    }, []);

    // console.log("state.isslogged", state.isslogged, state);
    return (
        <React.Fragment>
            <React.Suspense fallback={<div>Loading...</div>}>
                <SnackbarProvider maxSnack={6}>
                    <Navbar/>

                    <CookieConsent
                        location="bottom"
                        buttonText="I agree"
                        cookieName="CookieConsent"
                        buttonStyle={{color: "#d4efff", background: "#5268ff", fontSize: "13px"}}
                        style={{textAlign: "center", background: "#3f51b5"}}
                        expires={150}
                    >
                        This website uses cookies.
                    </CookieConsent>

                    {props.children}
                </SnackbarProvider>
            </React.Suspense>
        </React.Fragment>
    );
}

export default App;

App.propTypes = {
    children: PropTypes.any,
};
