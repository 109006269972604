// import {Link, navigate} from "@reach/router";
export const getCookie = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const setCookie = (cname, cvalue, exdays) => {
    // console.log("index.php 271", cname, cvalue, exdays);
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export async function post_return(url, dataPost, headers_ = {'Content-type': 'application/x-www-form-urlencoded'}) {
    const jwt = getCookie('jwt');
    // if (jwt.length  > 30) {
    dataPost.jwt = jwt;
    // if (dataPost.mode == 112) console.log("dataPost", dataPost);
    // console.log("dataPost", dataPost, url);
    // console.log(headers_);
    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(dataPost),
        headers: headers_
        // todo - add headers fix error handler
    });
    // if (dataPost.mode == 112)console.log("response", response);
    const json = await response.json();
    // if (dataPost.mode == 112)console.log("json", json);
    return {
        status: response.status,
        body: json
    };
    // } //else navigate("/login")
}

/*
* useEffect(() => {
        (async function() {
            try {
                //if (dataPost.email !== undefined)
                {
                    const response = await post_return(url.GET_TELEMETRY, dataPost);
                    //console.log("response", response);
                    setshowLoader(false);
                    if (response.status === 200) {
                    let array = create_arrayDG(response.body);
                        // navigate("/login")
                    } else alert(response.body);
               // }
            } catch (e) {
                console.error('Error ' , e);
            }
        })();
    }, [postData]);
* */

// export async function post_return(url, dataPost) {
//     let retObj = {};
//     await fetch(url, {
//         method: 'POST',
//         body: JSON.stringify(dataPost),
//         headers: {
//             'Content-type': 'application/x-www-form-urlencoded'
//             // 'Authorization': `any`,
//             // 'Accept': 'application/json',
//             // 'Content-Type': 'application/json'
//         }
//     })
//         .then(res => {
//             retObj.status = res.status;
//             res.json();
//             console.log("result", res, retObj);
//         })
//         .then(
//             (result) => {
//                 console.log("result", result, retObj);
//                 retObj.body = result;
//
//                 return retObj;
//             },
//             // Примечание: важно обрабатывать ошибки именно в данном коллбэке,
//             // вместо блока catch(), чтобы мы не теряли исключения от
//             // реальных багов в компонентах.
//             (error) => {
//                 console.log("error", error);
//                 // this.setState({
//                 //     isLoaded: true,
//                 //     error
//                 // });
//             }
//         )
// }


// old v
// var xhr = new XMLHttpRequest();
// xhr.open("POST", API_URL_LA_LOGIN, true);
//
// //Передает правильный заголовок в запросе
// xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
//
// xhr.onreadystatechange = function () {//Вызывает функцию при смене состояния.
//     if (xhr.readyState === XMLHttpRequest.DONE ) {
//         // Запрос завершен. Здесь можно обрабатывать результат.
//         if (xhr.status === 200) {
//             const result = JSON.parse(this.responseText);
//             // console.log("responseText", JSON.parse(this.responseText));
//             checkIsLogged(true, dispatch);
//             setCookie("jwt", result.jwt, 1);
//             setCookie("u_id", result.u_id, 1);
//             setCookie("u_key", result.u_key, 1);
//             setCookie("u_name", u_email, 1);
//             navigate("/")
//         }
//         else {
//             alert('Ошибка ' + this.status);
//         }
//     }
// };
// xhr.onload = function () {
//     // for what it is need
// };
//
// xhr.onerror = function () {
//     alert('Ошибка ' + this.status);
// };
// xhr.send(JSON.stringify(dataPost));