import React, {useEffect,/*useEffect,*/ useState} from "react";
import useForm from "react-hook-form";
import {dateString} from "../../api/functions/timeTreatment";
import {post_return} from "../../api/functions/post";
import {Store} from "../../api/store/Store";
import {navigate} from "@reach/router";
import {infobar} from "../../api/functions/infobar";
import { useSnackbar } from 'notistack';

const ChartTM = React.lazy(() => import('../Chart/ChartWrapper'));

export default function Logger() {

    const {enqueueSnackbar} = useSnackbar();
    const {state} = React.useContext(Store);
    const url = state.urls; // console.log(url);

    let [manualIon, manualIon_edit] = useState("KH");

    let [manualIonTag, manualIonTag_edit] = useState("kh2");
    let [manualValue, manualValue_edit] = useState({});
    // console.log("manualValue", manualValue);
    const defaultDateTime = dateString(new Date(), 1);

    const {register, handleSubmit, setValue, errors} = useForm();

    function onSubmit(data) {
        data.renameProperty("value", manualIonTag);
        console.log("value", data);
        (async function () {
            try {
                const response = await post_return(url.IMPORT_TELEMETRY, data);
                if (response.status === 200) {
                    // alert(response.body.message);
                    infobar(`Analysis for ${manualIonTag} is saved: ${response.body.message}`, "success", enqueueSnackbar);
                    // console.log("---get array", response.body.message );
                } else {
                    infobar(`Analysis for ${manualIonTag} can't be saved: ${response.body.message}`, "error", enqueueSnackbar);
                }

            } catch (e) {
                infobar(`Analysis for ${manualIonTag} can't be saved: ${e}`, "error", enqueueSnackbar);

            }
        })();
    }

    function setTime() {
        setValue("event_time", dateString(new Date(), 1));
    }

    useEffect(() => {
        navigate("/logger");
    },[]);

    return (

        <div className="container mb-2 ">
            <section className="pricing-table">
                <div className="block-heading">
                    {/*<h2>Калькулятор для коррекции ионного состава воды</h2>*/}
                    {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo.</p>*/}
                </div>
                <div className="row justify-content-md-center">

                    <div className="col-md-5 col-lg-5 text-center ">
                        <div className="item">
                            <div className="heading mt-5">
                                <h3>Analysis LOGs</h3>
                            </div>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="btn-group btn-group-toggle mt-3" data-toggle="buttons"
                                     onClick={(event) => {
                                         manualIon_edit(event.target.firstChild.dataset.name);
                                         manualIonTag_edit(event.target.firstChild.dataset.tag);
                                     }}
                                >
                                    <label className="btn btn-outline-primary active">
                                        <input type="radio" data-name="KH" data-tag="kh2"
                                               autoComplete="off" defaultChecked={true}/>KH
                                    </label>
                                    <label className="btn btn-outline-primary">
                                        <input type="radio" data-name="Кальций" data-tag="Ca"
                                               autoComplete="off"/>Ca
                                    </label>

                                    <label className="btn btn-outline-primary">
                                        <input type="radio" data-name="Магний" data-tag="Mg"
                                               autoComplete="off"/>Mg
                                    </label>
                                    <label className="btn btn-outline-primary">
                                        <input type="radio" data-name="Нитраты" data-tag="NO3"
                                               autoComplete="off"/>NO₃
                                    </label>
                                    <label className="btn btn-outline-primary">
                                        <input type="radio" data-name="Фосфаты" data-tag="PO4"
                                               autoComplete="off"/>PO₄
                                    </label>
                                    <label className="btn btn-outline-primary">
                                        <input type="radio" data-name="Калий" data-tag="kalium"
                                               autoComplete="off"/>K
                                    </label>
                                    <label className="btn btn-outline-primary">
                                        <input type="radio" data-name="pH" data-tag="ph2"
                                               autoComplete="off"/>pH
                                    </label>
                                </div>

                                <div className="input-group input-group-sm mt-3 mb-4">

                                    <div className="input-group-prepend ">
                                        <div style={{width: 80, justifyContent: "center", alignItems: "center"}}
                                             className="input-group-text ">
                                            {manualIon}
                                        </div>
                                    </div>

                                    <input type="number" step="0.01" className="form-control form-control-sm"
                                           name="value"
                                           ref={register({
                                               required: 'error message'
                                           })}
                                           autoFocus={true}
                                           style={{width: 80}}
                                        // onChange={(event) => {
                                        //     manualValue_edit(event.target.value)
                                        // }}
                                    />
                                    {errors.singleErrorInput && "Your input is required"}

                                    <input type="datetime-local" className="form-control form-control-sm"
                                           name="event_time"
                                           ref={register}
                            // key={defaultDateTime}
                                           style={{width: 160}}
                                           defaultValue={defaultDateTime}
                                    />

                                    <input type="button" style={{width: 5}}
                                           name={manualIonTag}
                                           className="form-control form-control-sm btn-outline-secondary"
                                           value={"T"}
                                           onClick={(event) => {
                                               event.preventDefault();
                                               setTime();
                                           }}
                                    />
                                    <input type="submit" style={{width: 60}}
                                           name={manualIonTag}
                                           className="form-control form-control-sm btn-outline-secondary"
                                           value={"Save"}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                </div>


                <React.Suspense fallback={<div>Loading...</div>}>
                    <ChartTM
                             mode={state.requests[3]}
                             smusing={0}
                             div_id={"logger"}
                             ylabel={state.requests[3][1]}
                             y2label={undefined}
                             series={{ 'NO3': {axis: 'y2'}, 'PO4': {axis: 'y2'}, 'KH2': {axis: 'y2'}, 'pH': {axis: 'y2'} }}
                             xlabel={"Date"}
                             labels={['Date', "KH2", "ph2", "Ca", "Mg", "NO₃", "PO₄", "Kalium", "Param1", "Param2", 'id']}
                             tagName={['date',   "kh2",     "ph2",     "Ca",       "Mg",      "NO3",    "PO4",     "kalium",  "param1",  "param2", 'id']}
                             colors={state.logColours}
                             visibility={[ true, true, true, true, true, true, true, true, true, false ]}
                    />
                </React.Suspense>

                {/*<ul className="nav nav-tabs" id="myTab" role="tablist">*/}

                {/*    <li className="nav-item">*/}
                {/*        <a className={manualIonTag === "kh2" ? "nav-link active" : "nav-link"}*/}
                {/*           id="home-tab" data-toggle="tab" href="#home" role="tab"*/}
                {/*           aria-controls="home" aria-selected="true">KH</a>*/}
                {/*    </li>*/}
                {/*    <li className="nav-item">*/}
                {/*        <a className={manualIonTag === "Ca" ? "nav-link active" : "nav-link"}*/}
                {/*           id="profile-tab" data-toggle="tab" href="#profile" role="tab"*/}
                {/*           aria-controls="profile" aria-selected="false">Ca</a>*/}
                {/*    </li>*/}
                {/*    <li className="nav-item">*/}
                {/*        <a className={manualIonTag === "Mg" ? "nav-link active" : "nav-link"}*/}
                {/*           id="contact-tab" data-toggle="tab" href="#contact" role="tab"*/}
                {/*           aria-controls="contact" aria-selected="false">Mg</a>*/}
                {/*    </li>*/}
                {/*    <li className="nav-item">*/}
                {/*        <a className={manualIonTag === "NO3" ? "nav-link active" : "nav-link"}*/}
                {/*           id="contact-tab" data-toggle="tab" href="#contact" role="tab"*/}
                {/*           aria-controls="contact" aria-selected="false">NO₃</a>*/}
                {/*    </li>*/}
                {/*    <li className="nav-item">*/}
                {/*        <a className={manualIonTag === "PO4" ? "nav-link active" : "nav-link"}*/}
                {/*           id="contact-tab" data-toggle="tab" href="#contact" role="tab"*/}
                {/*           aria-controls="contact" aria-selected="false">PO₄</a>*/}
                {/*    </li>*/}
                {/*    <li className="nav-item">*/}
                {/*        <a className={manualIonTag === "kalium" ? "nav-link active" : "nav-link"}*/}
                {/*           id="contact-tab" data-toggle="tab" href="#contact" role="tab"*/}
                {/*           aria-controls="contact" aria-selected="false">K</a>*/}
                {/*    </li>*/}
                {/*    <li className="nav-item">*/}
                {/*        <a className={manualIonTag === "ph2" ? "nav-link active" : "nav-link"}*/}
                {/*           id="contact-tab" data-toggle="tab" href="#contact" role="tab"*/}
                {/*           aria-controls="contact" aria-selected="false">pH</a>*/}
                {/*    </li>*/}
                {/*</ul>*/}
                {/*<div className="tab-content" id="myTabContent">*/}
                {/*    <div className={manualIonTag === "kh2" ? "tab-pane fade show active" : "tab-pane fade"} id="home"*/}
                {/*         role="tabpanel" aria-labelledby="home-tab">*/}
                {/*        Lorem ipsum dolor sit amet,*/}
                {/*    </div>*/}
                {/*    <div className={manualIonTag === "Ca" ? "tab-pane fade show active" : "tab-pane fade"} id="profile"*/}
                {/*         role="tabpanel" aria-labelledby="profile-tab">*/}
                {/*        2Lorem ipsum dolor sit amet, consectetur*/}
                {/*    </div>*/}

                {/*    <div className={manualIonTag === "Mg" ? "tab-pane fade show active" : "tab-pane fade"} id="contact"*/}
                {/*         role="tabpanel" aria-labelledby="contact-tab">*/}
                {/*        3Lorem ipsum , consectetur adip*/}
                {/*    </div>*/}

                {/*    <div className={manualIonTag === "NO3" ? "tab-pane fade show active" : "tab-pane fade"} id="contact"*/}
                {/*         role="tabpanel" aria-labelledby="contact-tab">*/}
                {/*        4Lmet, consectetur adip*/}
                {/*    </div>*/}

                {/*    <div className={manualIonTag === "PO4" ? "tab-pane fade show active" : "tab-pane fade"} id="contact"*/}
                {/*         role="tabpanel" aria-labelledby="contact-tab">*/}
                {/*        5Lorem ipsum dolor sit amet, m dolor sit amet, consectetur adip*/}
                {/*    </div>*/}

                {/*    <div className={manualIonTag === "kalium" ? "tab-pane fade show active" : "tab-pane fade"}*/}
                {/*         id="contact" role="tabpanel" aria-labelledby="contact-tab">*/}
                {/*        Lore, hjkh adip*/}
                {/*    </div>*/}

                {/*    <div className={manualIonTag === "ph2" ? "tab-pane fade show active" : "tab-pane fade"} id="contact"*/}
                {/*         role="tabpanel" aria-labelledby="contact-tab">*/}
                {/*        Lorem ipsu*/}
                {/*    </div>*/}
                {/*</div>*/}
            </section>

        </div>
    );
}

Object.defineProperty(
    Object.prototype,
    'renameProperty',
    {
        writable: false, // Cannot alter this property
        enumerable: false, // Will not show up in a for-in loop.
        configurable: false, // Cannot be deleted via the delete operator
        value: function (oldName, newName) {
            // Do nothing if the names are the same
            if (oldName === newName) {
                return this;
            }
            // Check for the old property name to
            // avoid a ReferenceError in strict mode.
            if (this.hasOwnProperty(oldName)) {
                this[newName] = this[oldName];
                delete this[oldName];
            }
            return this;
        }
    }
);

