import React from "react";
import {getCookie} from "../functions/post";

export const Store = React.createContext();
const rout = "api_test";

const initialState = {
    lang: getCookie("LANG") ? getCookie("LANG") : "EN",
    aqVol: 450,
    isslogged: false,
    pump_count: [1, 2, 3, 4],
    requests: [
        [60, "pH"],
        [50, "Temperature"],
        [70, "Carbonate hardness"],
        [80, "Analysis"],
        [115, "Consumption,  ml/day"],
        [110, "Consumption, ml"],
        [90, "Air properties"],
    ],
    get_pomps: false,
    needUpdate: 1234678,
    needUpdate11111: 11111,
    avatar: null,
    // "https://pbs.twimg.com/profile_images/840269670309625859/p6Pp6MH6_400x400.jpg",
    urls: {
        LA_LOGIN: `https://labaqua.net/${rout}/login.php`,
        VALIDATE_TOKEN: `https://labaqua.net/${rout}/validate_token.php`,
        GET_TELEMETRY: `https://labaqua.net/${rout}/get_telemetry.php`,
        GET_CONSUMPTION: `https://labaqua.net/${rout}/consumption.php`,
        EDIT_TELEMETRY: `https://labaqua.net/${rout}/edit_telemetry.php`,
        GET_SETTINGS: `https://labaqua.net/${rout}/get_settings.php`,
        IMPORT_SETTINGS: `https://labaqua.net/${rout}/import_settings.php`,
        IMPORT_TELEMETRY: `https://labaqua.net/${rout}/import_telemetry.php`,
        EDIT_USER: `https://labaqua.net/${rout}/update_user.php`,
        UPLOAD_PHOTO: `https://labaqua.net/${rout}/img_uploader.php`,
        GET_PHOTOS: `https://labaqua.net/${rout}/get_photos.php`,
        REGISTRATION: `https://labaqua.net/${rout}/create_user.php`
    },
    //           "kh2",     "ph2",     "Ca",       "Mg",      "NO3",    "PO4",     "kalium",  "param1",  "param2",
    logColours: ["#009c9c", "#e83e8c", "#007bff", "#fd7e14", "#ff3547", "#6f42c1", "#795548", "#007E33", "#ff6464"],

    pumpUsedVolume: [0, 0, 0, 0],
    dosers: [
        {
            pump: 1,
            name: "Component A",
            turnedOn: false,
            calVal: 81,
            mlDay: 60,

            solutionVol: 5,
            usedSalt:[3,0],
            saltMass:100,

            timers: [
                // [0, 7.3]
            ]
        },
        {
            pump: 2,
            name: "Component B",
            turnedOn: false,
            calVal: 82,
            mlDay: 60,

            solutionVol: 5,
            usedSalt:[2,0],
            saltMass:100,

            timers: [
                // [0, 7.3],
                // [8640, 8647.3]
            ]
        },
        {
            pump: 3,
            name: "Component C",
            turnedOn: false,
            calVal: 83,
            mlDay: 60,

            solutionVol: 5,
            usedSalt:[4,0],
            saltMass:100,

            timers: [
                // [0, 7.3],
                // [8640, 8647.3],
                // [17280, 17287.3]
            ],
        },
        {
            pump: 4,
            name: "Component D",
            turnedOn: false,
            calVal: 84,
            mlDay: 60,

            solutionVol: 5,
            usedSalt:[0,0],
            saltMass:100,

            timers: [
                // [0, 7.3],
                // [8640, 8647.3],
                // [17280, 17287.3],
                // [18280, 18287.3]
            ]
        }
    ],
    saltsUA: [
        [ // 0
            ["Нітрати", "NO₃⁻", "ppm"],
            [   // molecular weight && correspond increase of ion && solubility
                // http://www.theplantedtank.co.uk/calculator.htm
                // [formula, name, MW salt, MW of ion, solubility],
                ["NaNO₃", "Нітрат натрію", 85, 63, 87.6],
                ["KNO₃", "Нітрат калію", 101.1, 63, 31.6],
                ["Mg(NO₃)₂", "Нітрат магнію", 148.3, 63 * 2, 73.3],
                ["Ca(NO₃)₂", "Нітрат кальцію", 164.1, 63 * 2, 56.3]
            ]
        ],
        [ // 1
            ["Фосфати", "PO₄³⁻", "ppm"],
            [
                ["KH₂PO₄", "Дігидрофосфат калію", 136.1, 95, 22.6],
                ["K₂HPO₄", "Гідрофосфат калію", 174.2, 95, 149.25]
            ]
        ],
        [ // 2
            ["Карбонатна жорсткість", "KH", "dKH"],
            [   // 1 dKh = 0.357 meq/L => m(NaHCO₃) == 0.03g => 84g increase dKH to 2800 but historically, for
                // to convert g to ppm third parameter multiplied to 1000x, so we should divide & get => 2.8
                ["NaHCO₃", "Гідрокарбонат натрію (харчова сода)", 84, 2.8, 9.59], //
                ["Na₂СO₃", "Карбонат натрію, безводний (пральна сода)", 53/*106*/, 2.8/*5.603*/, 21.8],
                ["Na₂СO₃ · 10H₂O", "Карбонат натрію, 10-водний (пральна сода)", 174.2, 2.805, 9.59],
                ["NaHCO₃ + Na₂СO₃", "Суміш гідрокарбонату и карбонату натрію", 84, 2.805, 235, [["NaHCO₃", "Na₂СO₃"], 0.50, 0.3153]]
            ]
        ],
        [ // 3
            ["Кальцій", "Ca²⁺", "ppm"],
            [ //   0                   1                 2      3     4
                ["CaCl₂", "Хлорид кальцію, безводний", 110.98, 40.1, 74.5], //
                ["CaCl₂ · 2H₂O", "Хлорид кальцію, 2-водний", 147.01, 40.1, 29.4],
                ["CaCl₂ · 6H₂O", "Хлорид кальцію, 6-водний", 219.08, 40.1, 21.9]
            ]
        ],
        [ // 4
            ["Магний", "Mg²⁺", "ppm"],
            [
                [["MgCl₂ · 6H₂O"], "Хлорид магнію, безводний", [203.30], [24.3], [235]], //
                [["MgSO₄ · 7H₂O"], "Сульфат магнію, 7-водний", [246.47], [24.3], [113]],
                [["MgSO₄ · 7H₂O + MgCl₂ · 6H₂O"], "Суміш хлориду магнію і сульфату магнию", 203.30, 24.3, 113, [["MgSO₄ · 7H₂O", "MgCl₂ · 6H₂O"], 0.111, 0.889]], // [["MgSO₄ · 7H₂O", "MgCl₂ · 6H₂O"], 0.111, 0.889], //
                [["Tropic Marine (C)"], "Tropic Marine, component C", [171.4286], [24.3], [25]]
            ]
        ],
        [ // 5
            ["Балінг", "Mg²⁺", "ppm"],
            [
                [["Tropic Marine (C)"], "Tropic Marine, component C", [171.4286], [24.3], [25]],
                [["MgSO₄ · 7H₂O + MgCl₂ · 6H₂O"], "Суміш хлориду магнію і сульфату магнию", 203.30, 24.3, 113, [["MgSO₄ · 7H₂O", "MgCl₂ · 6H₂O"], 0.5769, 0.4231]], // [["MgSO₄ · 7H₂O", "MgCl₂ · 6H₂O"], 0.111, 0.889], //
                [["MgCl₂ · 6H₂O"], "Хлорид магнію, безводний", [203.30], [24.3], [235]], //
                [["MgSO₄ · 7H₂O"], "Сульфат магнію, 7-водний", [246.47], [24.3], [113]]
            ]
        ]
    ],
    saltsRU: [
        [ // 0
            ["Нитраты", "NO₃⁻", "ppm"],
            [   // molecular weight && correspond increase of ion && solubility
                // http://www.theplantedtank.co.uk/calculator.htm
                // [formula, name, MW salt, MW of ion, solubility],
                ["NaNO₃", "Нитрат натрия", 85, 63, 87.6],
                ["KNO₃", "Нитрат калия", 101.1, 63, 31.6],
                ["Mg(NO₃)₂", "Нитрат магния", 148.3, 63 * 2, 73.3],
                ["Ca(NO₃)₂", "Нитрат кальция", 164.1, 63 * 2, 56.3]
            ]
        ],
        [ // 1
            ["Фосфаты", "PO₄³⁻", "ppm"],
            [
                ["KH₂PO₄", "Дигидрофосфат калия", 136.1, 95, 22.6],
                ["K₂HPO₄", "Гидрофосфат калия", 174.2, 95, 149.25]
            ]
        ],
        [ // 2
            ["Карбонатная жесткость", "KH", "dKH"],
            [   // 1 dKh = 0.357 meq/L => m(NaHCO₃) == 0.03g => 84g increase dKH to 2800 but historically, for
                // to convert g to ppm third parameter multiplied to 1000x, so we should divide & get => 2.8
                ["NaHCO₃", "Гидрокарбонат натрия (пищевая сода)", 84, 2.8, 9.59], //
                ["Na₂СO₃", "Карбонат натрия, безводный (стиральная сода)", 53/*106*/, 2.8/*5.603*/, 21.8],
                ["Na₂СO₃ · 10H₂O", "Карбонат натрия, 10-водный (стиральная сода)", 174.2, 2.805, 9.59],
                ["NaHCO₃ + Na₂СO₃", "Смесь гидрокарбоната и карбоната натрия", 84, 2.805, 235, [["NaHCO₃", "Na₂СO₃"], 0.50, 0.3153]]
            ]
        ],
        [ // 3
            ["Кальций", "Ca²⁺", "ppm"],
            [ //   0                   1                 2      3     4
                ["CaCl₂", "Хлорид кальция, безводный", 110.98, 40.1, 74.5], //
                ["CaCl₂ · 2H₂O", "Хлорид кальция, 2-водный", 147.01, 40.1, 29.4],
                ["CaCl₂ · 6H₂O", "Хлорид кальция, 6-водный", 219.08, 40.1, 21.9]
            ]
        ],
        [ // 4
            ["Магний", "Mg²⁺", "ppm"],
            [
                [["MgCl₂ · 6H₂O"], "Хлорид магния, безводный", [203.30], [24.3], [235]], //
                [["MgSO₄ · 7H₂O"], "Сульфат магния, 7-водный", [246.47], [24.3], [113]],
                [["MgSO₄ · 7H₂O + MgCl₂ · 6H₂O"], "Смесь хлорида магния и сульфата магния", 203.30, 24.3, 113, [["MgSO₄ · 7H₂O", "MgCl₂ · 6H₂O"], 0.111, 0.889]], // [["MgSO₄ · 7H₂O", "MgCl₂ · 6H₂O"], 0.111, 0.889], //
                [["Tropic Marine (C)"], "Tropic Marine, component C", [171.4286], [24.3], [25]]
            ]
        ],
        [ // 5
            ["Балинг", "Mg²⁺", "ppm"],
            [
                [["Tropic Marine (C)"], "Tropic Marine, component C", [171.4286], [24.3], [25]],
                [["MgSO₄ · 7H₂O + MgCl₂ · 6H₂O"], "Смесь хлорида магния и сульфата магния", 203.30, 24.3, 113, [["MgSO₄ · 7H₂O", "MgCl₂ · 6H₂O"], 0.5769, 0.4231]], // [["MgSO₄ · 7H₂O", "MgCl₂ · 6H₂O"], 0.111, 0.889], //
                [["MgCl₂ · 6H₂O"], "Хлорид магния, безводный", [203.30], [24.3], [235]], //
                [["MgSO₄ · 7H₂O"], "Сульфат магния, 7-водный", [246.47], [24.3], [113]]
            ]
        ]
    ],
    saltsEN: [
        [ // 0
            ["Nitrates", "NO₃⁻", "ppm"],
            [   // molecular weight && correspond increase of ion && solubility
                // http://www.theplantedtank.co.uk/calculator.htm
                // [formula, name, MW salt, MW of ion, solubility],
                ["NaNO₃", "Sodium nitrate", 85, 63, 87.6],
                ["KNO₃", "Potassium nitrate", 101.1, 63, 31.6],
                ["Mg(NO₃)₂", "Magnesium nitrate", 148.3, 63 * 2, 73.3],
                ["Ca(NO₃)₂", "Calcium nitrate", 164.1, 63 * 2, 56.3]
            ]
        ],
        [ // 1
            ["Phosphates", "PO₄³⁻", "ppm"],
            [
                ["KH₂PO₄", "Potassium dihydrogen phosphate", 136.1, 95, 22.6],
                ["K₂HPO₄", "Potassium monohydrogen phosphate", 174.2, 95, 149.25]
            ]
        ],
        [ // 2
            ["Carbonate hardness", "KH", "dKH"],
            [   // 1 dKh = 0.357 meq/L => m(NaHCO₃) == 0.03g => 84g increase dKH to 2800 but historically, for
                // to convert g to ppm third parameter multiplied to 1000x, so we should divide & get => 2.8
                ["NaHCO₃", "Sodium bicarbonate (baking soda)", 84, 2.8, 9.59], //
                ["Na₂СO₃", "Sodium carbonate, anhydrous", 53/*106*/, 2.8/*5.603*/, 21.8],
                ["Na₂СO₃ · 10H₂O", "Sodium carbonate, decahydrate (washing soda)", 174.2, 2.805, 9.59],
                ["NaHCO₃ + Na₂СO₃", "Mixture of Sodium bicarbonate & carbonate", 84, 2.805, 235, [["NaHCO₃", "Na₂СO₃"], 0.50, 0.3153]]
            ]
        ],
        [ // 3
            ["Calcium", "Ca²⁺", "ppm"],
            [ //   0                   1                 2      3     4
                ["CaCl₂", "Calcium chloride, anhydrous", 110.98, 40.1, 74.5], //
                ["CaCl₂ · 2H₂O", "Calcium chloride, dihydrate", 147.01, 40.1, 29.4],
                ["CaCl₂ · 6H₂O", "Calcium chloride, hexahydrate", 219.08, 40.1, 21.9]
            ]
        ],
        [ // 4
            ["Magnesium", "Mg²⁺", "ppm"],
            [
                [["MgCl₂ · 6H₂O"], "Magnesium chloride, anhydrous", [203.30], [24.3], [235]], //
                [["MgSO₄ · 7H₂O"], "Magnesium sulfate heptahydrate", [246.47], [24.3], [113]],
                [["MgSO₄ · 7H₂O + MgCl₂ · 6H₂O"], "Mixture of Magnesium chloride & sulfate", 203.30, 24.3, 113, [["MgSO₄ · 7H₂O", "MgCl₂ · 6H₂O"], 0.111, 0.889]], // [["MgSO₄ · 7H₂O", "MgCl₂ · 6H₂O"], 0.111, 0.889], //
                [["Tropic Marine (C)"], "Tropic Marine, component C", [171.4286], [24.3], [25]]
            ]
        ],
        [ // 5
            ["Baling", "Mg²⁺", "ppm"],
            [
                [["Tropic Marine (C)"], "Tropic Marine, component C", [171.4286], [24.3], [25]],
                [["MgSO₄ · 7H₂O + MgCl₂ · 6H₂O"], "Mixture of Magnesium chloride & sulfate", 203.30, 24.3, 113, [["MgSO₄ · 7H₂O", "MgCl₂ · 6H₂O"], 0.5769, 0.4231]], // [["MgSO₄ · 7H₂O", "MgCl₂ · 6H₂O"], 0.111, 0.889], //
                [["MgCl₂ · 6H₂O"], "Magnesium chloride, anhydrous", [203.30], [24.3], [235]], //
                [["MgSO₄ · 7H₂O"], "Magnesium sulfate heptahydrate", [246.47], [24.3], [113]]
            ]
        ]
    ]
};

function reducer(state, action) {
    // console.log("--reducer---++++state++++", state);
    // console.log("/+/+/+/FETCH_DATA", action);
    let dosers = [...state.dosers];
    switch (action.type) {
        case "FETCH_DATA":
            console.log("++++FETCH_DATA episodes: action.payload", action.payload);
            return {...state, episodes: action.payload};
        case "IS_LOGGED":
            // console.log("++++FETCH_DATA isslogged", action.payload);
            return {...state, isslogged: action.payload.isslogged};
        case "LANG":
            // console.log("++++FETCH_DATA isslogged", action.payload);
            return {...state, lang: action.payload.lang};
        case "SET_AVATAR":
            // console.log("++++FETCH_DATA avatar", action.payload);
            return {...state, avatar: action.payload.avatar};
        case "UPDATEPOMP_MLDAY":
            dosers[action.payload.pump].mlDay = parseFloat(action.payload.mlDay);
            console.log("++++UPDATEPOMP_MLDAY --", dosers);
            return {...state, dosers};
        case "UPDATEPOMP_NAME":
            dosers[action.payload.poump].name = action.payload.name;
            console.log("++++UPDATEPUMP_NAME --", dosers);
            return {...state, dosers};
        case "UPDATEPOMP_CALVAL":
            dosers[action.payload.pump].calVal = parseFloat(action.payload.calVal);
            console.log("++++UPDATEPOMP_CALVAL --", dosers);
            return {...state, dosers};
        case "UPDATEPOMP_DOSCNT":
            dosers[action.payload.pump].mlDay = parseFloat(action.payload.mlDay);
            console.log("++++UPDATEPOMP_DOSCNT --", dosers);
            return {...state, dosers: dosers};
        case "FETCH_PUMP_DATA":
            // eslint-disable-next-line no-case-declarations
            let arr = initialState.dosers;
            action.payload.dosers.map((dosator_i, index) => {
                for (let i = 0; i < 4; i++) {
                    if (dosator_i.pump === (i + 1)) {
                        arr[i] = dosator_i;
                        break;
                    }
                }
            });
            // console.log("++++FETCH_POMP_DATA arr", arr, "++++FETCH_POMP_DATA action", action.payload);
            return {...state, get_pomps: true, dosers: arr, needUpdate: Math.random()};

        case "UPDATEPOMP_USEDSALT":
            dosers[action.payload.pump].usedSalt = action.payload.usedSalt;
            // console.log("++++UPDATEPOMP_USEDSALT --", dosers);
            return {...state, dosers: dosers};
        case "UPDATEPOMP_SALTMASS":
            dosers[action.payload.pump].saltMass = action.payload.saltMass;
            // console.log("++++UPDATEPOMP_SALTMASS --", dosers);
            return {...state, dosers: dosers};
        case "UPDATEPOMP_SLNVLUME":
            dosers[action.payload.pump].solutionVol = action.payload.solutionVol;
            // console.log("++++UPDATEPOMP_SLNVLUME --", dosers);
            return {...state, dosers: dosers};
        case "FETCH_PUMP_USEDRGNT":
             // console.log("++++FETCH_PUMP_USEDRGNT -- ", action.payload);
            return {...state, pumpUsedVolume: action.payload};
        default:
            return state;
    }

}

export function StoreProvider(props) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    let value = {state, dispatch};
    return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
