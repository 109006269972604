import React, {useContext, useEffect} from 'react';
import {Link} from "@reach/router";
import {fetchPumpDataAction, fetchPumpUsedVolume} from "../../api/actions/Actions";
import {Store} from "../../api/store/Store";
import ScrollUpButton from "react-scroll-up-button";
import {Spring, animated} from "react-spring/renderprops";
import {useSpring} from "react-spring";
// todo https://www.react-spring.io/docs/props/keyframes


export default function HomePage() {
    const {state, dispatch} = useContext(Store);
    const url = state.urls;
    useEffect(() => {
        fetchPumpDataAction(dispatch, url.GET_SETTINGS);
        fetchPumpUsedVolume(dispatch, url.GET_SETTINGS);
    }, []);

    const props = useSpring({
        from: {color: 'blue'},
        to: {color: `rgb(255, 0, 0)`}
    });

    return (
        <React.Fragment>
            <React.Suspense fallback={<div>Loading...</div>}>
                <div id="top">
                    <div>
                        <ScrollUpButton/>
                    </div>
                    <header className={""}>
                        <Spring
                            from={{opacity: 0}}
                            to={{opacity: 1}}
                        >
                            {props =>
                                <section className="view hm-gradient" style={props} id="intro">
                                    <div className="full-bg-img d-flex align-items-center">
                                        <div className="container">
                                            <div className="row no-gutters">
                                                <div className="col-md-10 col-lg-6 text-center text-md-left margins">
                                                    <div className="white-text">
                                                        <div className="wow fadeInLeft" data-wow-delay="0.3s">
                                                            {/*<Spring*/}
                                                            {/*    from={{opacity: 0.6, marginTop: -50}}*/}
                                                            {/*    to={{opacity: 1, marginTop: 50}}*/}
                                                            {/*>*/}
                                                            <h1 className="h1-responsive font-bold mt-sm-5">
                                                                LabAqua - control your aquarium from any around the
                                                                world
                                                            </h1>
                                                            {/*</Spring>*/}


                                                        </div>
                                                        <br/>

                                                        <div className="wow fadeInLeft" data-wow-delay="0.3s">
                                                            {/*<a className="btn btn-outline-white" href="#">*/}
                                                            {/*    /!*Learn more*!/*/}
                                                            {/*</a>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="h5 white-text">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda
                                                    doloribus facere iste obcaecati, quaerat quasi qui quisquam sequi?
                                                    Cum, dolores illum laborum magni perferendis porro quibusdam ratione
                                                    reiciendis sed ut.

                                                    {/*Цель этого сайта – автоматизация управления и мониторинг аквариума с*/}
                                                    {/*помощью*/}
                                                    {/*оборудования <i>LabAqua</i>. Также, на сайте есть все необходимые*/}
                                                    {/*калькуляторы для поддержания оптимального ионного состава воды и*/}
                                                    {/*дневник для записи параметров воды.*/}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            }
                        </Spring>
                    </header>
                    <div id="content">
                        {  /*
                        <section className="row no-gutters" id="features">
                            <div className="col-lg-3 col-md-6 col-sm-12 deep-purple lighten-1 text-white">
                                <div className="p-5 text-center wow zoomIn" data-wow-delay=".1s"><i
                                    className="fa fa-line-chart fa-2x"/>
                                    <div className="h5 mt-3">Agile Frameworks</div>
                                    <p className="mt-5">Leverage agile frameworks to provide a robust synopsis for high
                                        level overviews.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 purple lighten-1 text-white">
                                <div className="p-5 text-center wow zoomIn" data-wow-delay=".3s"><i
                                    className="fa fa-industry fa-2x"/>
                                    <div className="h5 mt-3">Corporate Strategy</div>
                                    <p className="mt-5">Iterative approaches to corporate strategy foster collaborative
                                        thinking to further the
                                        overall value proposition</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 teal lighten-1 text-white">
                                <div className="p-5 text-center wow zoomIn" data-wow-delay=".5s"><i
                                    className="fa fa-users fa-2x"/>
                                    <div className="h5 mt-3"> Workplace Diversity</div>
                                    <p className="mt-5">Organically grow the holistic world view of disruptive
                                        innovation via workplace
                                        diversity and empowerment.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 light-blue lighten-1 text-white">
                                <div className="p-5 text-center wow zoomIn" data-wow-delay=".7s"><i
                                    className="fa fa-bullhorn fa-2x"/>
                                    <div className="h5 mt-3"> Survival Strategies</div>
                                    <p className="mt-5">Bring to the table win-win survival strategies to ensure
                                        proactive domination.</p>
                                </div>
                            </div>
                        </section>
                        */
                        }

                        <section className="text-center py-5 grey lighten-4" id="about">
                            <div className="container mt-3">
                                <animated.div style={props} className="mb-5">
                                    <i>*Page under development</i>
                                </animated.div>
                            </div>
                            <div className="container">
                                {/*<div className="wow fadeIn">*/}
                                {/*    <h2 className="h1 pt-5 pb-3">Приветствую Вас на сайте <i>LabAqua</i>!</h2>*/}
                                {/*    <div className="px-5 mb-5 pb-3 lead blue-grey-text">*/}
                                {/*        <p>Интернет вещей</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="row">
                                    <div className="col-md-4 mb-r wow fadeInUp" data-wow-delay=".3s"><i
                                        className="fa fa-dashboard fa-3x orange-text"/>
                                        <h3 className="h4 mt-3">Design</h3>
                                        <p className="mt-3 blue-grey-text">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit
                                            maiores nam, aperiam
                                            minima assumenda deleniti
                                            hic.
                                        </p>
                                    </div>
                                    <div className="col-md-4 mb-r wow fadeInUp" data-wow-delay=".4s"><i
                                        className="fa fa-comments-o fa-3x cyan-text"/>
                                        <h3 className="h4 mt-3">Feedback</h3>
                                        <p className="mt-3 blue-grey-text">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit
                                            maiores nam, aperiam
                                            minima assumenda deleniti
                                            hic.
                                        </p>
                                    </div>
                                    <div className="col-md-4 mb-r wow fadeInUp" data-wow-delay=".5s"><i
                                        className="fa fa-cubes fa-3x red-text"/>
                                        <h3 className="h4 mt-3">Execution</h3>
                                        <p className="mt-3 blue-grey-text">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit
                                            maiores nam, aperiam
                                            minima assumenda deleniti
                                            hic.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="white" id="projects">
                            <div className="container">
                                <div className="wow fadeIn">
                                    <h2 className="text-center h1 pt-5 pb-2">About devices</h2>
                                    <div className="px-5 pb-3 lead blue-grey-text text-center">
                                        <p className={"mb-5"}>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda beatae,
                                            commodi consequuntur deserunt dolorum eos excepturi fugiat laudantium soluta
                                            voluptate. Aut corporis ducimus ea hic laborum nam omnis sed veritatis.

                                            {/*Устройства функционируюn по принципу "Интернета вещей" - встроеный модуль*/}
                                            {/*WiFi - позволяет управлять устройствами и контролировать параметры находясь*/}
                                            {/*как угодно*/}
                                            {/*далеко от аквариума. Анализ сохраненных в облаке параметров (температура,*/}
                                            {/*рН, КН и т.д.)*/}
                                            {/*можно использовать для повышения комфорта обиталей Вашего аквариума.*/}
                                        </p>
                                        <Spring
                                            from={{marginLeft: -1000}}
                                            to={{marginLeft: "auto"}}
                                        >
                                            {props =>
                                                <div style={props} className="container" id="iot">
                                                    <div className="text-center rounded img-fluid animated fadeInUp"
                                                         id="iot1"/>
                                                </div>
                                            }
                                        </Spring>
                                        <p className={"mt-1"}>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut nihil
                                            numquam perspiciatis repellat saepe voluptatibus! Consectetur enim ipsa
                                            quibusdam reiciendis? Ad consectetur, neque officia provident quas
                                            reprehenderit tenetur voluptas.

                                            {/*Онлайн мониторинг и управлкение устройством возможно с помощью Telegram*/}
                                            {/*bota, поэтому даже при очень*/}
                                            {/*плохом качестве интернета Вы всегда сможеть контрлировать Ваш аквариум*/}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="py-5" id="projects">
                            <div className="container">

                                <h3 className="h4 text-center mt-1">Aquarium controller</h3>
                                <div className="row wow pt-5 fadeInLeft" data-wow-delay=".3s">

                                    <div className="col-lg-6 col-xl-5 pr-lg-5 pb-5"><img
                                        className="img-fluid rounded z-depth-2"
                                        src="https://labaqua.net/img/img_site/about/eufilia.jpg"
                                        alt="project image"/>
                                    </div>
                                    <div className="col-lg-6 col-xl-7 pl-lg-5 pb-4">
                                        <div className="row mb-3">
                                            <div className="col-1 mr-1"><i className="fa fa-book fa-2x cyan-text"/>
                                            </div>
                                            <div className="col-10">
                                                <h5 className="font-bold">Control</h5>
                                                <div className="grey-text">
                                                    <ul>
                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab,
                                                        assumenda illo maiores, molestias necessitatibus nisi officia
                                                        provident quod quos ratione, repudiandae sequi v
                                                        {/*<li>Автодолив. При каждом добавлении воды Вы получите сообщение*/}
                                                        {/*    в*/}
                                                        {/*    Telegram о*/}
                                                        {/*    продолжительности работы насоса. Датчик низкого уровня в*/}
                                                        {/*    емкости*/}
                                                        {/*    автодолива*/}
                                                        {/*    напомнит пополнить запас воды.*/}
                                                        {/*</li>*/}
                                                        {/*<li>Поддержание тепмпературы воды в заданном интервале*/}
                                                        {/*    (охлаждение и нагрев).*/}
                                                        {/*</li>*/}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-1 mr-1"><i className="fa fa-code fa-2x red-text"/>
                                            </div>
                                            <div className="col-10">
                                                <h5 className="font-bold">Info</h5>
                                                <div className="grey-text">
                                                    <ul>
                                                        <li>
                                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et
                                                            libero molestiae odit tempora.
                                                            {/*Запросы с Telegram на получение информации о текущем*/}
                                                            {/*состоянии*/}
                                                            {/*датчиков (температура воды и*/}
                                                            {/*воздуха, влажность, уровень воды, рН, ORP, наличие воды в*/}
                                                            {/*емкости автодолива).*/}
                                                        </li>
                                                        <li>Информирование о протечке воды.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1 mr-1"><i
                                                className="fa fa-money fa-2x deep-purple-text"/></div>
                                            <div className="col-10">
                                                <h5 className="font-bold">Measurement</h5>
                                                <div className="grey-text">
                                                    <ul>
                                                        <li> рН & ORP.</li>
                                                        <li>temperature of water and air, humidity.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr/>

                                <h3 className="h4 text-center mt-5">Doser</h3>
                                <div className="row pt-5 wow fadeInRight" data-wow-delay=".3s">
                                    <div className="col-lg-6 col-xl-7 mb-3">
                                        <div className="row mb-3">
                                            <div className="col-1 mr-1"><i
                                                className="fa fa-bar-chart fa-2x indigo-text"/></div>
                                            <div className="col-10">
                                                <h5 className="font-bold">Control</h5>
                                                <div className="grey-text">
                                                    <ul>
                                                        <li>Settings <Link className=" blue-grey-text" to="/doser">schedule
                                                            & volume</Link> of dosing.
                                                        </li>
                                                        <li>Any pump could be turned off.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-1 mr-1"><i className="fa fa-music fa-2x pink-text"/>
                                            </div>
                                            <div className="col-10">
                                                <h5 className="font-bold">Info</h5>
                                                <div className="grey-text">
                                                    <ul>
                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab
                                                        assumenda ducimus earum
                                                        {/*<li>при изменении параметров через веб интерфейс – пользователь*/}
                                                        {/*    получает сообщение в Telegram об изменении расписания.*/}
                                                        {/*</li>*/}
                                                        {/*<li>Отображение остатка реагентов для каждой помпы.</li>*/}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-1 mr-1"><i
                                                className="fa fa-smile-o fa-2x blue-text"/></div>
                                            <div className="col-10">
                                                <h5 className="font-bold">Settings</h5>
                                                <div className="grey-text">
                                                    <ul>
                                                        <li>online calibration</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-5 mb-3">
                                        <img className="img-fluid rounded z-depth-2"
                                             src="https://labaqua.net/img/img_site/about/grib.jpg"
                                             alt="project image"/>
                                    </div>
                                </div>

                                <hr/>

                                <h3 className="h4 text-center mt-5">КН-station</h3>
                                <div className="row wow pt-5 fadeInLeft" data-wow-delay=".3s">
                                    <div className="col-lg-6 col-xl-5 pr-lg-5 pb-5"><img
                                        className="img-fluid rounded z-depth-2"
                                        src="https://labaqua.net/img/img_site/about/pectinia.jpg"
                                        alt="project image"/>
                                    </div>
                                    <div className="col-lg-6 col-xl-7 pl-lg-5 pb-4">
                                        <div className="row mb-3">
                                            <div className="col-1 mr-1"><i className="fa fa-book fa-2x cyan-text"/>
                                            </div>
                                            <div className="col-10">
                                                <h5 className="font-bold">Control</h5>
                                                <div className="grey-text">
                                                    <ul>
                                                        <li>
                                                            Determination of carbonate hardness of water according to a
                                                            given
                                                            schedule (for example, once every two hours)
                                                        </li>
                                                        <li>Correction of reagent volumes for Balling</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-1 mr-1"><i className="fa fa-code fa-2x red-text"/>
                                            </div>
                                            <div className="col-10">
                                                <h5 className="font-bold">Info</h5>
                                                <div className="grey-text">
                                                    <ul>
                                                        <li>
                                                            Analysis results are stored in the cloud and sent to
                                                            Telegram
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1 mr-1"><i
                                                className="fa fa-money fa-2x deep-purple-text"/></div>
                                            <div className="col-10">
                                                <h5 className="font-bold">Remoute control</h5>
                                                <div className="grey-text">
                                                    <ul>
                                                        <li>Start titration a specified number of times with a message from
                                                            Telegram.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                        <section className="py-5 white" id="team">
                            <div className="container">
                                <div className="wow fadeIn">
                                    <h2 className="h1 pt-5 pb-3 text-center">Our team members</h2>
                                    <p className="px-5 mb-5 pb-3 lead text-center blue-grey-text">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet
                                        numquam iure provident
                                        voluptate
                                        esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur
                                        accusamus veniam.
                                    </p>
                                </div>
                                <div className="row mb-lg-4 center-on-small-only">
                                    <div className="col-lg-6 col-md-12 mb-r wow fadeInLeft" data-wow-delay=".3s">
                                        <div className="col-md-6 float-left"><img
                                            className="img-fluid rounded z-depth-1 mb-3"
                                            src="https://labaqua.net/img/img_site/about/Tigra.jpg"
                                            alt="team member"/></div>
                                        <div className="col-md-6 float-right">
                                            <div className="h4">Mr Tigra</div>
                                            <h6 className="font-bold blue-grey-text white mb-4">Main murchatel</h6>
                                            <p className="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing
                                                elit. Quod eos id
                                                officiis hic tenetur.</p>
                                            <a href="https://twitter.com">
                                                <i className="fa fa-twitter"/><span className="ml-1">@tigra</span></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 mb-r wow fadeInRight" data-wow-delay=".3s">
                                        <div className="col-md-6 float-left"><img
                                            className="img-fluid rounded z-depth-1 mb-3"
                                            src="https://labaqua.net/img/img_site/about/Mishel.jpg"
                                            alt="team member"/></div>
                                        <div className="col-md-6 float-right">
                                            <div className="h4">Ms Michel</div>
                                            <h6 className="font-bold blue-grey-text white mb-4">Sub main murchatel</h6>
                                            <p className="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing
                                                elit. Quod eos id
                                                officiis hic tenetur.
                                            </p>
                                            <a href="https://twitter.com">
                                                <i className="fa fa-twitter"/><span className="ml-1">@michel</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/*<div>*/}
                        {/*    <h3 className={"text-center mb-5 mt-5"}>Settings  <Link*/}
                        {/*        to="/instructions">manual</Link> </h3>*/}
                        {/*</div>*/}
                        <section id="contact" style={{backgroundImage: "url('img/panorama-3094696_1920.jpg')"}}>
                            <div className="rgba-black-strong py-5">
                                <div className="container">
                                    <div className="wow fadeIn">
                                        <h2 className="h1 text-white pt-5 pb-3 text-center">Contact us</h2>
                                        <p className="text-white px-5 mb-5 pb-3 lead text-center">
                                            If you have any question please contact me
                                        </p>
                                    </div>
                                    <div className="card mb-5 wow fadeInUp" data-wow-delay=".4s">
                                        <div className="card-body p-5">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <form action="https://formspree.io/youremail@example.com"
                                                          method="POST">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="md-form">
                                                                    <input className="form-control" id="name"
                                                                           type="text" name="name"
                                                                           required="required"/>
                                                                    <label htmlFor="name">Your name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="md-form">
                                                                    <input className="form-control" id="email"
                                                                           type="text" name="_replyto"
                                                                           required="required"/>
                                                                    <label htmlFor="email">Your email</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="md-form">
                                                                    <input className="form-control" id="subject"
                                                                           type="text" name="subject"
                                                                           required="required"/>
                                                                    <label htmlFor="subject">Subject</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="md-form">
                                                                 <textarea className="md-textarea" id="message"
                                                                           name="message"
                                                                           required="required"/> <br/>
                                                                    <label htmlFor="message">Your message</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="center-on-small-only mb-4">
                                                            <button className="btn btn-indigo ml-0" type="submit"><i
                                                                className="fa fa-paper-plane-o mr-2"/> Send
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="col-md-4">
                                                    <ul className="list-unstyled text-center">
                                                        <li className="mt-4">
                                                            <i className="fa fa-map-marker indigo-text fa-2x"/>
                                                            <p className="mt-2">Kyiv, Ukraine</p>
                                                        </li>
                                                        <li className="mt-4">
                                                            <i className="fa fa-telegram indigo-text fa-2x"/>
                                                            <p className="mt-2">
                                                                <a href="https://t.me/igorlab">igorlab</a>
                                                            </p>
                                                        </li>
                                                        <li className="mt-4">
                                                            <i className="fa fa-envelope indigo-text fa-2x"/>
                                                            <p className="mt-2">tigorlab@gmail.com</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </React.Suspense>
        </React.Fragment>
    );
}
