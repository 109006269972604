import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {getCookie, post_return, setCookie} from "../../../api/functions/post";

import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {Store} from "../../../api/store/Store";

import Badge from '@material-ui/core/Badge';
import {setAvatar} from "../../../api/actions/Actions";
import {infobar} from "../../../api/functions/infobar";
import {useSnackbar} from "notistack";

// import {navigate} from "@reach/router";

export default function EditUser() {
    const {enqueueSnackbar} = useSnackbar();
    const {state, dispatch} = React.useContext(Store);
    const url = state.urls;
    const classes = useStyles();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [passwordOld, setPasswordOld] = useState('');
    const [passwordNew1, setPasswordNew1] = useState('');
    const [passwordNew2, setPasswordNew2] = useState('');
    const [agreement, setAgreement] = useState(false);
    const [postData, setPostData] = useState({});
    const [showLoader, setshowLoader] = useState(false);
    const [imgFile, setimgFile] = useState();
        //"https://pbs.twimg.com/profile_images/840269670309625859/p6Pp6MH6_400x400.jpg");

    function newPSWchck(newPSW2) {
        if (passwordNew1 === newPSW2) {
            setAgreement(true);
            setPasswordNew2(newPSW2);
        } else setAgreement(false);
    }

    function handle_Submit(event) {
        event.preventDefault();
        const postData_ = {
            firstname: firstName,
            lastname: lastName,
            email: email,
            password: passwordNew2,
            passwordOld
        };
        setPostData(postData_);
        // console.log( 'postData:', postData);
    }

    useEffect(() => {
        (async function () {
            try {
                // console.log(postData.email);
                if (postData.email !== undefined) {
                    const response = await post_return(url.EDIT_USER, postData);
                    // console.log("response", response);
                    setshowLoader(false);
                    if (response.status === 200) {
                        //navigate("/login")
                        infobar(`${response.body.message}`, "success", enqueueSnackbar);
                        // console.log(response.body);
                        setCookie("jwt", response.body.jwt, 1);
                    } else {
                        infobar(`Error: ${response.body.message}`, "error", enqueueSnackbar);
                    }
                }
            } catch (e) {
                infobar(`Error: ${e}`, "error", enqueueSnackbar);
            }
        })();
    }, [postData]);

    useEffect(() => {

        setimgFile(state.avatar);

    }, [state.avatar]);


    useEffect(() => {
        (async function () {
            try {
                // console.log("postData.email", postdt);
                const response = await post_return(url.VALIDATE_TOKEN, {});
                setshowLoader(false);
                if (response.status === 200) {
                    // console.log("postData.email", response);
                    setFirstName(response.body.data.firstname);
                    setLastName(response.body.data.lastname);
                    setEmail(response.body.data.email);
                    infobar(`User info is edited: ${response.body.message}`, "success", enqueueSnackbar);
                    // navigate("/login")
                } else {
                    infobar(`Error: ${response.body.message}`, "error", enqueueSnackbar);

                }
            } catch (e) {
                infobar(`Error: ${e}`, "error", enqueueSnackbar);

            }
        })();
    }, []);

    const handleImageUpload = event => {
        setshowLoader(true);
        const files = event.target.files;
        const formData = new FormData();
        formData.append('fileToUpload', files[0]);
        formData.append('jwt', getCookie('jwt'));
        formData.append('mode', 101);
        formData.append('description', "profile photo");
        (async function () {
            try {
                const response = await fetch(url.UPLOAD_PHOTO, {
                    method: 'POST',
                    body: formData
                });
                const json = await response.json();
                if (response.status === 200) {
                    const avatar = "https://labaqua.net/img/" + json.link;
                    console.log("json", json, avatar);
                    setAvatar(avatar, dispatch);

                    setshowLoader(false);
                } else {
                    setshowLoader(false);
                    infobar(`Picture size is too big`, "error", enqueueSnackbar);
                    // console.log('response.body ', response.body);
                }
            } catch (e) {
                console.log('Error ', e);
                setshowLoader(false);
                infobar(`Error on photo uploading: ${e}`, "error", enqueueSnackbar);
            }
        })();
    };

    // https://labaqua.net/img/30-12-2019-157770639877777777777777777777.gif

    return (
        <Container component="main" maxWidth="xs">

            <CssBaseline/>
            <div className={classes.paper}>

                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                    visible={showLoader}
                    style={{
                        position: 'fixed',
                        left: (window.clientWidth - 50) / 2 + 'px',
                        top: 50+'%',
                        zIndex: 99
                    }}
                />

                <div className={classes.root}>
                    {/*<StyledBadge*/}
                    {/*    overlap="circle"*/}
                    {/*    anchorOrigin={{*/}
                    {/*        vertical: 'bottom',*/}
                    {/*        horizontal: 'right',*/}
                    {/*    }}*/}
                    {/*    variant="dot"*/}
                    {/*>*/}
                    {/*    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />*/}
                    {/*</StyledBadge>*/}

                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        badgeContent={
                            <Avatar
                                alt={getCookie('u_name')} src="/static/images/avatar/1.jpg"
                                style={ {"backgroundColor": "#3366CC"}}
                            >
                                <input style={{display: "none"}} type="file" id="myfile" name="myfile" accept="image/*"
                                       onChange={(e) => {
                                           handleImageUpload(e);
                                       }}
                                />
                                <label htmlFor="myfile"><i className="fa fa-camera mt-2"/></label>
                            </Avatar>
                        }
                    >
                        <Avatar alt={getCookie('u_name')}
                                className={classes.large}
                                src={imgFile}
                        />
                    </Badge>
                </div>

                <Typography component="h1" variant="h5">
                    Edit <i>{email}</i> profile:
                </Typography>

                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                value={firstName}
                                onInput={e => setFirstName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lname"
                                value={lastName}
                                onInput={e => setLastName(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password old"
                                type="password"
                                id="password"
                                onInput={e => setPasswordOld(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password New"
                                type="password"
                                id="password"
                                onInput={e => setPasswordNew1(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password New (repeat)"
                                type="password"
                                id="password"
                                onInput={(e) => newPSWchck(e.target.value)}
                            />
                        </Grid>

                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        href={"#"}
                        disabled={!agreement}
                        onClick={(e) => {
                            handle_Submit(e);
                            setshowLoader(true);
                        }}
                    >
                        Edit profile
                    </Button>
                </form>
            </div>
            <Box mt={5}>
                <Copyright/>
            </Box>
        </Container>
    );
}


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                labaqua.net
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    large: {
        width: theme.spacing(25),
        height: theme.spacing(25),
    },
}));


const SmallAvatar = withStyles(theme => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
    },
}))(Avatar);

const StyledBadge = withStyles(theme => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);


