export function toHHMMSS(time) { // Number.prototype.toHHMMSS =
    let sec_num = parseInt(time, 10); // don't forget the second paramthis
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
}

export function timeToSec(in_time) {
    return new Date("1970/01/01 " + in_time + "+0000").getTime() / 1000;

}

export function dateString(date, mode = 0) {

    let year = date.getFullYear();
    let month = "0" + (date.getMonth() + 1);
    let day = "0" + date.getDate();
    let hours = "0" + (date.getHours()); // + (date.getTimezoneOffset()/60)
    let minutes = "0" + date.getMinutes();
    let seconds = "0" + date.getSeconds();
    if (mode === 0) {
        return year + '/' + month.substr(-2) + '/' + day.substr(-2) + ' ' + hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    } else if (mode === 1) {
        return year + '-' + month.substr(-2) + '-' + day.substr(-2) + 'T' + hours.substr(-2) + ':' + minutes.substr(-2);
    }

}

    export function round01(number) {
        return Math.round(number * 10) / 10;
    }

    export function round001(number) {
        return Math.round(number * 100) / 100;
    }