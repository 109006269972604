import {getCookie, setCookie} from "../../api/functions/post";
import {checkIsLogged} from "../../api/actions/Actions";
import {checkLang} from "../../api/actions/Actions";
import React, {useContext, useEffect, useState} from "react";
import {Store} from "../../api/store/Store";
import {Link, navigate} from "@reach/router";

export default function Navbar() {
    const {state, dispatch} = useContext(Store);

    const classActive = ["nav-item", "nav-item", "nav-item", "nav-item", "nav-item", "nav-item", "nav-item", "nav-item"];
    let [imgURL, imgURL_edit] = useState(state.avatar);

    useEffect(() => {
        imgURL_edit(state.avatar);
    }, [state]);

    if (window.location.pathname === "/") classActive[0] = "nav-item active";
    else if (window.location.pathname === "/calc") classActive[1] = "nav-item active";
    else if (window.location.pathname === "/logger") classActive[2] = "nav-item active";
    else if (window.location.pathname === "/doser") classActive[3] = "nav-item active";
    else if (window.location.pathname === "/settings") classActive[4] = "nav-item active";
    else if (window.location.pathname === "/telemetry") classActive[5] = "nav-item active";

    // else if (window.location.pathname === "/settings") classActive[6] = "nav-item active";
    function initLangStore() {
        setCookie("LANG", "EN");
        return "EN";
    }

    // console.log("imgURL", imgURL);
    return (
        <React.Fragment>
            <React.Suspense fallback={<div>Loading...</div>}>
                <>
                    <nav className="navbar navbar-inverse navbar-expand-xl navbar-dark {/*fixed-top*/}">
                        <div className="navbar-header d-flex col">
                            <a className="navbar-brand" href="/">
                                <i className="fa fa-cube"/>
                                Lab<b>Aqua</b>
                            </a>
                            {/*<Link to='/'>labaqua</Link>*/}
                            <button
                                type="button"
                                data-target="#navbarCollapse"
                                data-toggle="collapse"
                                className="navbar-toggle navbar-toggler ml-auto"
                            >
                                <span className="navbar-toggler-icon"/>
                                <span className="icon-bar"/>
                                <span className="icon-bar"/>
                                <span className="icon-bar"/>
                            </button>
                        </div>
                        {/* Collection of nav links, forms, and other content for toggling */}
                        <div
                            id="navbarCollapse"
                            className="collapse navbar-collapse justify-content-start"
                        >
                            {/*<form className="navbar-form form-inline">
                                <div className="input-group search-box">
                                    <input
                                        type="text"
                                        id="search"
                                        className="form-control"
                                        placeholder="Search here..."
                                    />
                                    <span className="input-group-addon">
                                        <i className="material-icons"></i>
                                    </span>
                                </div>
                            </form>*/}
                            <ul className="nav navbar-nav navbar-right ml-auto">
                                <li className={classActive[0]}>
                                    <Link to="/" className="nav-link">
                                        <i className="fa fa-home"/>
                                        <span>Home</span>
                                    </Link>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <Link to="#" className="nav-link">*/}
                                {/*        <i className="fa fa-gears"/>*/}
                                {/*        <span>Projects</span>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li className={classActive[1]}>
                                    <Link to="/calc" className="nav-link">
                                        <i className="fa fa-pie-chart"/>
                                        <span>Calculator</span>
                                        {/*<Link to='/calc'>Calculator</Link>*/}
                                    </Link>
                                </li>

                                {state.isslogged ?
                                    <>
                                        <li className={classActive[5]}>

                                            <Link to='/telemetry' className="nav-link">
                                                <i className="fa fa-mixcloud"/>
                                                <span>Telemetry</span>
                                            </Link>
                                        </li>
                                        <li className={classActive[2]}>

                                            <Link to='/logger' className="nav-link">
                                                <i className="fa fa-bar-chart"/>
                                                <span>Logger</span>
                                            </Link>
                                        </li>
                                        <li className={classActive[3]}>
                                            <Link to="/doser" className="nav-link">
                                                <i className="fa fa-flask"/>
                                                <span>Dosers</span>
                                            </Link>
                                        </li>
                                        <li className={classActive[4]}>
                                            <Link to="/settings" className="nav-link">
                                                <i className="fa fa-cogs"/>
                                                <span>Settings</span>
                                            </Link>
                                        </li>

                                        {/*<li className="nav-item">*/}
                                        {/*    <a href="#" className="nav-link">*/}
                                        {/*        <i className="fa fa-envelope"/>*/}
                                        {/*        <span>Messages</span>*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                        <li className="nav-item">
                                            <Link to="#" className="nav-link">
                                                <i className="fa fa-bell"/>
                                                <span>Notifications</span>
                                            </Link>
                                        </li>

                                        <li className="nav-item dropdown">
                                            <a
                                                href="#"
                                                data-toggle="dropdown"
                                                className="nav-link dropdown-toggle user-action"
                                            >
                                                {" "} {getCookie('LANG') ? getCookie('LANG') : initLangStore()}
                                                <b className="caret"/>
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a href="#" className="dropdown-item"
                                                       onClick={() => {
                                                           setCookie("LANG", "UA");
                                                           checkLang("UA", dispatch);
                                                       }}
                                                    >UA </a>
                                                </li>

                                                <li className="divider dropdown-divider"/>
                                                <li>
                                                    <a href="#" className="dropdown-item"
                                                       onClick={() => {
                                                           setCookie("LANG", "EN");
                                                           checkLang("EN", dispatch);
                                                       }}
                                                    >EN </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item dropdown">
                                            <a
                                                href="#"
                                                data-toggle="dropdown"
                                                className="nav-link dropdown-toggle user-action"
                                            >
                                                <img
                                                    src={imgURL != null ? imgURL : "https://pbs.twimg.com/profile_images/840269670309625859/p6Pp6MH6_400x400.jpg"} // *********************************************
                                                    className="avatar"
                                                    alt="Avatar"
                                                />{" "} {getCookie('u_mail')} <b className="caret"/>
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a href="/edituser" className="dropdown-item">
                                                        <i className="fa fa-user-o"/> Edit Profile
                                                        {/*<Link to='/edituser'>Edit User</Link>*/}
                                                    </a>
                                                </li>
                                                {/*<li>*/}
                                                {/*    <a href="#" className="dropdown-item">*/}
                                                {/*        <i className="fa fa-calendar-o"/> Calendar*/}
                                                {/*    </a>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                {/*    <a href="#" className="dropdown-item">*/}
                                                {/*        <i className="fa fa-sliders"/> Settings*/}
                                                {/*    </a>*/}
                                                {/*</li>*/}
                                                <li className="divider dropdown-divider"/>
                                                <li>
                                                    <a href="#" className="dropdown-item"
                                                       onClick={() => {
                                                           // console.log('try logout');
                                                           checkIsLogged(false, dispatch);
                                                           setCookie("jwt", -1, 1);
                                                           setCookie("u_mail", -1, 1);
                                                           //window.location.reload();
                                                           navigate("/login")
                                                       }}
                                                    >
                                                        <i className="material-icons"></i> Logout
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                    </> :
                                    <>
                                        <li className="nav-item dropdown">
                                            <a
                                                href="#"
                                                data-toggle="dropdown"
                                                className="nav-link dropdown-toggle user-action"
                                            >
                                                {" "} {getCookie('LANG')} <b className="caret"/>
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a href="#" className="dropdown-item"
                                                       onClick={() => {
                                                           setCookie("LANG", "UA");
                                                           checkLang("UA", dispatch);
                                                       }}
                                                    >UA </a>
                                                </li>

                                                <li className="divider dropdown-divider"/>
                                                <li>
                                                    <a href="#" className="dropdown-item"
                                                       onClick={() => {
                                                           setCookie("LANG", "EN");
                                                           checkLang("EN", dispatch);
                                                       }}
                                                    >EN </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/login" className="nav-link">
                                                <i className="fa fa-bell"/>
                                                <span>Login</span>
                                            </a>
                                        </li>
                                    </>
                                }
                            </ul>
                        </div>
                    </nav>
                </>
            </React.Suspense>
        </React.Fragment>
    );

}

