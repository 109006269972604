import {post_return} from "../functions/post";

export const fetchPumpUsedVolume = async (dispatch, url) => {
    // console.log('********* fetchPumpDataAction ');
    const dataPost = {
        mode: "113"
    };

    await (async function () {
        try {
            const response = await post_return(url, dataPost);
            if (response.status === 200) {
                //console.log("usedVolume", response.body.usedVolume);
                const usedVolume = response.body.usedVolume;
                const pumpUsedVolume = [0, 0, 0, 0];
                usedVolume.length > 0 && usedVolume.map((element) => {
                    pumpUsedVolume[element.pump - 1] = parseFloat(element.usedVol);
                    //console.log( "pomp: " + pump + " usedVol: " + usedVol, pumpUsedVolume);
                });

                return dispatch({
                    type: 'FETCH_PUMP_USEDRGNT',
                    payload: pumpUsedVolume
                });
            } else {
                alert(response.body);
                // infobar(`Error: ${e}`, "error", enqueueSnackbar);
            }
        } catch (e) {
            console.error('Error ', e);
            // infobar(`Error: ${e}`, "error", enqueueSnackbar);
        }
    })();
};
export const fetchPumpDataAction = async (dispatch, url) => {
    // console.log('********* fetchPumpDataAction ');
    const dataPost = {
        mode: "112"
    };

    await (async function () {
        try {
            const response = await post_return(url, dataPost);
            if (response.status === 200) {
                // console.log(response);
                // console.log(response.body);
                return dispatch({
                    type: 'FETCH_PUMP_DATA',
                    payload: response.body
                });
            } else {
                alert(response.body);
                // infobar(`Error: ${e}`, "error", enqueueSnackbar);
            }
        } catch (e) {
            console.error('Error ', e);
            // infobar(`Error: ${e}`, "error", enqueueSnackbar);
        }
    })();
};

export const updatePomp_name = (name, pump, dispatch) => {
    let dispatchObj = {
        type: 'UPDATEPOMP_NAME',
        payload: {
            name: name,
            pump: pump
        }
    };
    // console.log(name);
    return dispatch(dispatchObj);
};

export const checkIsLogged = (islogged, dispatch) => {
    let dispatchObj = {
        type: 'IS_LOGGED',
        payload: {
            isslogged: islogged
        }
    };
    // console.log("action ", isslogged);
    return dispatch(dispatchObj);
};


export const checkLang = (lang, dispatch) => {
    let dispatchObj = {
        type: 'LANG',
        payload: {
            lang: lang
        }
    };
    return dispatch(dispatchObj);
};

export const setAvatar = (avatar, dispatch) => {
    let dispatchObj = {
        type: 'SET_AVATAR',
        payload: {
            avatar: avatar
        }
    };
    // console.log("action ", isslogged);
    return dispatch(dispatchObj);
};

export const updatePomp_usedSalt = (usedSalt, pump, dispatch) => {
    let dispatchObj = {
        type: 'UPDATEPOMP_USEDSALT',
        payload: {
            usedSalt: usedSalt,
            pump: pump
        }
    };
    return dispatch(dispatchObj);
};
export const updatePomp_saltMass = (saltMass, pump, dispatch) => {
    let dispatchObj = {
        type: 'UPDATEPOMP_SALTMASS',
        payload: {
            saltMass: saltMass,
            pump: pump
        }
    };
    return dispatch(dispatchObj);
};

export const updatePomp_solutionVol = (solutionVol, pump, dispatch) => {
    let dispatchObj = {
        type: 'UPDATEPOMP_SLNVLUME',
        payload: {
            solutionVol: solutionVol,
            pump: pump
        }
    };
    return dispatch(dispatchObj);
};


// export const updatePomp_calVal = (calVal, pump, dispatch) => {
//     let dispatchObj = {
//         type: 'UPDATEPOMP_CALVAL',
//         payload: {
//             calVal: calVal,
//             pump: pump
//         }
//     };
//     return dispatch(dispatchObj);
// };
