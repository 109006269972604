import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {Store} from "../../api/store/Store";
// import { Line } from 'react-chartjs-2';
const Line = React.lazy(() => import('react-chartjs-2'));

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
    },
}));

export default function CalcWaterChange() {
    const classes = useStyles();

    const {state} = React.useContext(Store);
    let [lang, setLang] = React.useState(state.lang);


    let [concentration, setConcentration] = React.useState(state.lang);

    let [aquaVol, setAquaVol] = React.useState("");
    let [changeVol, setChagVol] = React.useState("");
    let [initConcentration, setInitConcentr] = React.useState("");
    let [freshConc, setFreshConc] = React.useState("");
    let [changeCount, setChangeCount] = React.useState("");

    React.useEffect(() => {
        setLang(state.lang);
        if (lang === "EN") {
            setConcentration("Concentration");
            setAquaVol("Tank volume, L");
            setChagVol("Change volume");
            setInitConcentr("Initial concentration");
            setFreshConc("Concentration in fresh water");
            setChangeCount("Changes count");
        }
        else if (lang === "UA") {
            setConcentration("Концентрація");
            setAquaVol("Об'єм акваріума, л");
            setChagVol("Об'єм підміни");
            setInitConcentr("Початкова концентрація");
            setFreshConc("Концентрація в свіжій воді");
            setChangeCount("Кількість підмін");
        }
        else if (lang === "RU") {
            setConcentration("Концентрация");
            setAquaVol("Объем аквариума, л");
            setChagVol("Объем подмены");
            setInitConcentr("Начальная концентрация");
            setFreshConc("Концентрация в подмениваемой воде");
            setChangeCount("Количество подмен");
        }
    });



    let [akvVol, editAkvVol] = useState(450);
    let [podmVol, editPodmVol] = useState(50);
    let [initConc, editInitConc] = useState(1000);
    let [concVpodm, editConcVpodm] = useState(430);
    let [kolvPodm, editKolvPodm] = useState(25);
    let [graph, editGraph] = useState([]);

    useEffect(() => {
        let raschet = [{c: initConc, v: podmVol, i: 0}]
        for (let i = 1; i < kolvPodm; i++) {
            raschet.push(
                {
                    c: (raschet[i - 1].c * (akvVol - podmVol) + podmVol * concVpodm) / akvVol,
                    v: podmVol * (i + 1),
                    i: i
                }
            )
        }

        editGraph(raschet);
        console.log('graph', graph);

    }, [akvVol, podmVol, initConc, concVpodm, kolvPodm]);

    const data = {
        labels: graph.map(obj=>obj.v),
        datasets: [
            {
                label: {concentration},
                data: graph.map(obj=>obj.c),
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    return (

        <Grid container spacing={3}>
            <Grid item xs={2}>
                <Paper className={classes.paper}>

                    <form className={classes.root} noValidate autoComplete="off">
                        <div>
                            <TextField id="standard-size-small"
                                       label={aquaVol}
                                       size="small"
                                       type="number"
                                       InputLabelProps={{shrink: true,}}
                                       defaultValue={450}
                                       onChange={(event) => {
                                           // updatepump_calVal(event.target.value, pump, dispatch)
                                           editAkvVol(event.target.value);
                                       }}
                            />
                        </div>
                        <div>
                            <TextField id="standard-size-small"
                                       label={changeVol}
                                       size="small"
                                       type="number"
                                       InputLabelProps={{shrink: true,}}
                                       defaultValue={50}
                                       onChange={(event) => {
                                           editPodmVol(event.target.value);
                                       }}
                            />
                        </div>
                        <div>
                            <TextField id="standard-size-small"
                                       label={initConcentration}
                                       size="small"
                                       type="number"
                                       InputLabelProps={{shrink: true,}}
                                       defaultValue={1000}
                                       onChange={(event) => {
                                           editInitConc(event.target.value);
                                       }}
                            />
                        </div>
                        <div>
                            <TextField id="standard-size-small"
                                       label={freshConc}
                                       size="small"
                                       type="number"
                                       InputLabelProps={{shrink: true,}}
                                       defaultValue={430}
                                       onChange={(event) => {
                                           editConcVpodm(event.target.value);
                                       }}
                            />
                        </div>
                        <div>
                            <TextField id="standard-size-small"
                                       label={changeCount}
                                       size="small"
                                       type="number"
                                       InputLabelProps={{shrink: true,}}
                                       defaultValue={25}
                                       onChange={(event) => {
                                           editKolvPodm(event.target.value);
                                       }}
                            />
                        </div>

                    </form>
                </Paper>
            </Grid>
            <Grid item xs={9}>
                <Paper className={classes.paper}>
                    <div>
                        {/*<canvas id="myChart" width="400" height="400"/>*/}
                        <Line data={data} options={options}  type={'line'}/>
                    </div>
                </Paper>
            </Grid>

        </Grid>

    );
}
