import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {post_return} from "../../../api/functions/post";

import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {navigate} from "@reach/router";
import {infobar} from "../../../api/functions/infobar";
import {useSnackbar} from "notistack";

export default function SignUp() {
    const {enqueueSnackbar} = useSnackbar();

    const classes = useStyles();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName]   = useState('');
    const [email, setEmail]         = useState('');
    const [password, setPassword]   = useState('');
    const [agreement, setAgreement] = useState(false);
    const [postData, setPostData]   = useState({});
    const [showLoader, setshowLoader] = useState(false);


    function handleSubmit(event) {
        event.preventDefault();
        const postData_ = {
            firstname: firstName,
            lastname: lastName,
            email: email,
            password: password
        };
        setPostData(postData_);
        // console.log( 'postData:', postData);
    }

    const API_URL_REGISTRATION = "https://labaqua.net/api_test/create_user.php";

    useEffect(() => {
        (async function() {
            try {
                console.log(postData.email);
                if (postData.email !== undefined)
                {
                    const response = await post_return(API_URL_REGISTRATION, postData);
                    console.log("response", response);
                    setshowLoader(false);
                    if (response.status === 200) {
                        navigate("/login")
                    } else {
                        infobar(`Error: ${response.body}`, "error", enqueueSnackbar);
                    }
                }
            } catch (e) {
                infobar(`Error: ${e}`, "error", enqueueSnackbar);
            }
        })();
    }, [postData]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>

                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                    visible={showLoader}
                />

                <Avatar className={classes.avatar} >
                    <LockOutlinedIcon />
                </Avatar>

                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>

                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                value={firstName}
                                onInput={ e=>setFirstName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lname"
                                value={lastName}
                                onInput={ e=>setLastName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onInput={ e=>setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                value={password}
                                onInput={ e=>setPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I agree with conditions."
                                value={agreement}
                                onInput={ e=>setAgreement(e.target.checked)}
                            />
                        </Grid>
                    </Grid>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        href={"#"}
                        disabled={!agreement}
                        onClick={ (e) => {
                            handleSubmit(e);
                            setshowLoader(true);
                        }}
                    >
                        Sign Up
                    </Button>

                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form >
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>

        </Container>


    );
}


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                labaqua.net
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
