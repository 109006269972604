import React from "react";
import {Store} from '../../api/store/Store';
import useForm from "react-hook-form";
import {post_return} from "../../api/functions/post";
import {useSnackbar} from "notistack";
import {infobar} from "../../api/functions/infobar";


export default function Settings() {

    const {enqueueSnackbar} = useSnackbar();
    const {state} = React.useContext(Store);
    const url = state.urls;

    const {register, handleSubmit} = useForm();

    function onSubmit(data) {
        // event.preventDefault();
        console.log("value", data);
        data.mode = 50;
        (async function () {
            try {
                const response = await post_return(url.IMPORT_SETTINGS, data);
                if (response.status === 200) {
                    console.log(response.body);
                    infobar(`Settings saved. New temperature: ${data.t_water}.`, "success", enqueueSnackbar);
                } else {
                    infobar(`Settings can't be saved. ${response.body}.`, "error", enqueueSnackbar);
                }
            } catch (e) {
                infobar(`Settings can't be saved. ${e}.`, "error", enqueueSnackbar);
            }
        })();
    }

    return (
        <React.Fragment>
            <React.Suspense fallback={<div>Loading...</div>}>

                <div className="container">
                    <div>
                        Форма для задания новых значений температуры и допустимого отклонения
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="input-group  mt-2">
                            <div className="input-group-prepend ">
                                {/*<div className="input-group-text  input-group-sm">Желаемая температура, ℃</div>*/}
                                <span className="input-group-text" id="inputGroup-sizing-sm">Температура, ℃</span>
                            </div>
                            <input className="form-control" type="number"
                                   step="0.25"
                                   defaultValue={24.5}
                                   ref={register}
                                   id="t_water"
                                   style={{width: 100}}
                                   name="t_water"
                                   placeholder="new T"
                            />

                            <input className="form-control" type="text"
                                   style={{width: 130}}
                                   defaultValue={"Threshold, ℃"} disabled/>

                            <input className="form-control" type="number"
                                   step="0.1"
                                   defaultValue={0.3}
                                   ref={register}
                                   style={{width: 100}}
                                   name="t_w_threshold"
                            />

                            <div className="input-group-append">
                                <button className="btn btn-secondary btn-sm" type="submit"

                                        onClick={(e) => {
                                            console.log("index", e);
                                        }}
                                >Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Suspense>
        </React.Fragment>
    );
}

