import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

import {setCookie, post_return} from "../../../api/functions/post";
import {navigate}      from "@reach/router"
import {checkIsLogged, fetchPumpDataAction} from "../../../api/actions/Actions";
import {Store}         from "../../../api/store/Store";
import {useSnackbar} from "notistack";
import {infobar} from "../../../api/functions/infobar";
import Loader from "react-loader-spinner";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                LabAqua
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
const IMG_LOG = "http://www.orangesmile.com/extreme/img/main/great-barrier-reef_1.jpg";
const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${IMG_LOG})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



export default function SignIn() {

    const {enqueueSnackbar} = useSnackbar();

    const classes = useStyles();
    const {state, dispatch } = React.useContext(Store);
    const url = state.urls;

    let [u_email, email_edit] = useState("");
    let [u_passw, passw_edit] = useState("");
    const [showLoader, setshowLoader] = useState(false);

    function login_post(dataPost) {
        // console.log("dataPost", dataPost);
        setshowLoader(true);
        (async function() {
            try {
                const response = await post_return(url.LA_LOGIN, dataPost);
                if (response.status === 200) {
                    const result = response.body;
                    //console.log(response.body);
                    checkIsLogged(true, dispatch);
                    setCookie("jwt", result.jwt, 1);
                    setCookie("u_mail", u_email, 1);
                    fetchPumpDataAction(dispatch, url.GET_SETTINGS);
                    infobar(`You are logged in`, "success", enqueueSnackbar);
                    console.log("you are not logged in", "error");
                    navigate("/");
                    setshowLoader(false);
                    // window.location.reload();
                } else {
                    setshowLoader(false);
                    infobar(`Error ${response.body}`, "error", enqueueSnackbar);
                }
            } catch (e) {
                infobar(`Error: ${e}`, "error", enqueueSnackbar);
            }
        })();
    }

    return (
        <Grid container component="main" className={classes.root}>

            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            visible={showLoader}
                            style={{
                                margin: "0 auto",
                                 position: 'absolute',
                                // right: (window.clientWidth ) / 2 + 150 + 'px',
                                top: 27.5+'%',
                                zIndex: 99
                            }}
                        />
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate
                          onSubmit={(event) => {
                              event.preventDefault();
                              console.log("event.target.value onChange", event.target.value);
                          }}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={
                                (event) => {
                                    event.preventDefault();
                                    email_edit(event.target.value);
                                }
                            }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={
                                (event) => {
                                    event.preventDefault();
                                    passw_edit(event.target.value);
                                }
                            }
                        />
                        {/*<FormControlLabel*/}
                        {/*    control={<Checkbox value="remember" color="primary"/>}*/}
                        {/*    label="Remember me"*/}
                        {/*/>*/}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={
                                (event) => {
                                    event.preventDefault();
                                    passw_edit(event.target.value);
                                    // console.log("s_email", s_email, "s_passw", s_passw);
                                    login_post({email: u_email, password: u_passw})
                                }
                            }
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/signup" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>
                            <Copyright/>
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}