import React, {useEffect} from 'react';
import {Store} from '../../api/store/Store';
import {navigate} from "@reach/router";
//import {getCookie, post_return} from "../../api/post";

const ChartTM = React.lazy(() => import('../Chart/ChartWrapper'));
const ChartTMC = React.lazy(() => import('../Chart/ChartWrapperC'));
//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

export default function HomePage() {
    const {state} = React.useContext(Store);

    useEffect(() => {
        navigate("/telemetry");
        navigate("/telemetry");
    },[]);

    return (
        <React.Fragment>
            <React.Suspense fallback={<div>Loading...</div>}>
                <div className="container">
                    <div className="">Telemetry of water parameters</div>
                    <div className="">
                        {/*<h5 className="card-title">Связь с контроллером установлена</h5>*/}
                        <br/>
                        {/*<hr/>*/}
                        {/*<br/>*/}

                        <ChartTM mode={state.requests[0]}
                                 key={1098234}
                                 div_id={"graph_ph"}
                                 ylabel={"pH"}
                                 y2label={undefined}
                                 series={{'pH': {axis: 'y2'}}}
                                 xlabel={"Date"}
                                 labels={["Date", "pH"]}
                                 tagName={["date", "ph1"]}
                                 colors={null}
                                 visibility={[true]}
                        />

                        <br/>
                        <hr/>
                        <br/>

                        <ChartTM mode={state.requests[6]}
                                 key={1098234789}
                                 div_id={"graph_co2"}
                                 ylabel={"CO2 ppm"}
                                 y2label={undefined}
                                 series={{'pH': {axis: 'y2'}}}
                                 xlabel={"Date"}
                                 labels={["Date", "CO2"]}
                                 tagName={["date", "value1"]}
                                 colors={null}
                                 visibility={[true]}
                                 smusing={1}
                        />

                        <br/>
                        <hr/>
                        <br/>

                        <ChartTM mode={state.requests[2]}
                                 key={2658761}
                                 div_id={"graph_KHooo"}
                                 ylabel={"KH"}
                                 y2label={undefined}
                                 series={{'KH': {axis: 'y1'}}}
                                 xlabel={"Date"}
                                 labels={['Date', 'KH']}
                                 tagName={['date', 'kh1']}
                                 colors={["#009c9c"]}
                                 visibility={[true]}
                        />
                        <br/>
                        <hr/>
                        <br/>

                        {/*//----------------------------------------------------------------------------------*/}
                        <ChartTM mode={state.requests[5]}
                                 pump={2}
                                 key={2658762}
                                 div_id={"graph_KH111"}
                                 ylabel={"KH_consumption, ml"}
                                 y2label={undefined}
                                 series={{'V(ml)': {axis: 'y1'}}}
                                 xlabel={"Date"}
                                 labels={['Date', 'V(ml)']}
                                 tagName={['date', 'vml', 'id']}
                                 colors={["#009c9c"]}
                                 visibility={[true]}
                                 url_s={state.urls.GET_CONSUMPTION}
                                 smusing={1}
                        />
                        <br/>
                        <hr/>
                        <br/>

                        <ChartTM mode={state.requests[4]}
                                 pump={2}
                                 key={265876212789}
                                 div_id={"graph_KH111mlday"}
                                 ylabel={"KH_consumption/day, ml"}
                                 y2label={undefined}
                                 series={{'V(ml)': {axis: 'y1'}}}
                                 xlabel={"Date"}
                                 labels={['Date', 'V(ml)/day']}
                                 tagName={['date', 'volume']}
                                 colors={["#009c9c"]}
                                 visibility={[true]}
                                 url_s={state.urls.GET_CONSUMPTION}
                                 smusing={1}
                        />
                        <br/>
                        <hr/>
                        <br/>

                        <ChartTM mode={state.requests[1]}
                                 key={37897}
                                 div_id={"graph_th"}
                                 ylabel={"Humidity, %"}
                                 y2label={undefined/*"Temperature, ℃"*/}
                                 series={{'T(water)': {axis: 'y2'}, 'T(air)': {axis: 'y2'}, 'T(set)': {axis: 'y2'}}}
                                 xlabel={"Date"}
                                 labels={['Date', 'Humidity', 'T(set)', 'T(air)', 'T(water)']}
                                 tagName={['date', 'humidity', 't_set', 't_air', 't_water']}
                                 colors={["#7663cc", "#889113", "#009c9c", "#ff6464"]}
                                 visibility={[false, true, true, true]}
                        />

                        <br/>
                    </div>
                </div>
            </React.Suspense>
        </React.Fragment>
    );
}
